@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

html {
    position: relative;
    min-height: 100%;
    margin: auto;
}

body {
    height: 100%;
    margin: 0 100px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 179px;
}

#root:not(.footer) {
    max-width: 1400px;
    margin: auto;
}

@media (max-width: 780px) and (min-width: 480px) {
    body {
        margin: 0 30px;
    }
}

@media (max-width: 480px) {
    body {
        margin: 0 10px;
    }
    
}