.stars {
    display: flex;
    gap: 10px;
}

.stars > img {
    height: 25px;
}

@media (max-width: 480px) {
    .stars > img {
        height: 15px;
    }
}