.footer {
    left: 100px;
    display: flex;
    margin: 0px -100px;
    background-color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 179px;
}

.footer > img {
    height: 40px;
    width: 122px;
}

.footer > p {
    margin: 20px;
    color: white;
}