.home {
    margin-bottom: 40px;
}

.home-banner {
    display: flex;
    height: 223px;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.home-banner > img {
    position: absolute;
    height: 223px;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
    filter : brightness(70%);
}

.home-banner > h1 {
    display: flex;
    z-index: 2;
    margin: 0;
    color: white;
    font-weight: inherit;
    font-size: 3em;
    text-align: center;
}

.home-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3%;
    row-gap: 40px;
    flex-wrap: wrap;
}

@media (max-width: 780px) and (min-width: 480px) {
    .home-banner > h1 {
        font-size: 2em;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .home-banner > h1 {
        display: flex;
        font-size: 1.5em;
        width: 250px;
    }

    .home-banner {
        height: 100px;
    }

    .home-banner > img {
        height: 100px;
        border-radius: 15px;
    }

    .home-cards {
        flex-direction: column;
        gap: inherit;
        row-gap: 10px;
    }
}