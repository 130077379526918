.banner {
    display: flex;
    justify-content: space-between;
    height: 68px;
    margin: 40px 0;
}

.banner > div {
    flex: 1;
    display: flex;
    align-items: center;
}

.banner-logo {
    width: 210px;
    height: 68px;
}

.banner-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    flex: 1;
    width: 136px;
    height: 34px;
    top: 17px;
    left: 931px;
}

.banner-nav-a {
    color: #FF6060;
    text-decoration: inherit;
}

.banner-nav-a:hover {
    color: #FF6060;
    text-decoration: underline;
}

.banner-nav-a_underline {
    color: #FF6060;
    text-decoration: underline;
}

@media (max-width: 780px) and (min-width: 480px) {
    .banner-logo {
        width: 145px;
        height: 47px;
    }
}

@media (max-width: 480px) {
    .banner-logo {
        width: 120px;
        height: 35px;
    }

    .banner > div:nth-child(2) {
        flex-basis: 180px;
    }

    .banner-nav > a {
        text-transform: uppercase;
    }
}