.a-card, .fakeCard {
    display: flex;
    height: 400px;
    width: 30%;
}

.card {
    display: flex;
    position: relative;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%), var(--img);
    background-size: cover;
    border-radius: 15px;
}

.card > p {
position: absolute;
width: 100px;
bottom: 20px;
left: 20px;
margin: 0;
color: white;
line-height: 1.4em;
}

@media (max-width: 780px) and (min-width: 480px) {
    .a-card, .fakeCard {
        height: 250px;
    }
}

@media (max-width: 480px) {
    .a-card, .fakeCard {
        height: 200px;
        width: 100%;
    }
}