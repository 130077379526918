.slideshow {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.slideshow-chevron_gauche {
    position: absolute;
    left: 20px;
    transform: rotate(180deg);
    z-index: 2;
}

.slideshow-div {
    display: flex;
    width: 100%;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.slideshow-div-img {
    position: absolute;
    left: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slideshow-div-img_display {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.slideshow-compteur {
    position: absolute;
    color: white;
    font-size: 1.3em;
    bottom: 0;
}

.slideshow-chevron_droit {
    position: absolute;
    right: 20px;
    z-index: 2;
}

.slideshow-none {
    display: none;
}

@media (max-width: 780px) and (min-width: 480px) {
    .slideshow-div {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .slideshow-div {
        height: 200px;
    }

    .slideshow-compteur {
        font-size: 1em;
    }

    .slideshow-chevron_gauche, .slideshow-chevron_droit {
        height: 30px;
    }
}