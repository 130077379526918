.tag {
    background-color: #FF6060;
    border-radius: 10px;
    padding: 5px 20px;
}

.tag > p {
    margin: inherit;
    color: white;
    font-size: 0.8em;
}

@media (max-width: 480px) {
    .tag {
        padding: 5px 15px;
    }

    .tag > p {
        font-size: 0.6em;
    }
}