.error {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error > h1 {
    color: #FF6060;
    font-size: 15em;
    margin: inherit;
}

.error > h2 {
    color: #FF6060;
    font-weight: inherit;
    margin: inherit;
}

.error > a {
    color: #FF6060;
    font-size: 0.8em;
    margin-top: 150px;
}

@media (max-width: 780px) and (min-width: 480px) {
    .error > h1 {
        font-size: 12em;
    }
    
    .error > h2 {
        font-weight: inherit;
        text-align: center;
    }
    
    .error > a {
        font-size: 0.8em;
        margin-top: 100px;
    }
}

@media (max-width: 480px) {
    .error > h1 {
        font-size: 8em;
    }
    
    .error > h2 {
        font-size: 0.8em;
    }
    
    .error > a {
        font-size: 0.7em;
        margin-top: 75px;
    }
}