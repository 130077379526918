.propos {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
}

.propos-banner {
    display: flex;
}

.propos-banner > img {
    height: 223px;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
    filter : brightness(70%);
}

.propos-collapse {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 50px;
}

@media (max-width: 480px) {
    .propos-collapse {
        padding: 0 5px;
    }
}